import { FC } from 'react';

import styled from 'styled-components';

import { Link, Typography } from 'common';
import Layout, { Banner, Content, Section } from 'common/Layout';

import { COLORS } from 'shared/constants';
import { GoogleAnalyticsGA4 } from 'shared/analytics/GoogleAnalyticsGA4';
import { EMetricAction, EMetricCategory } from 'shared/analytics/contracts';

const careersSrc = 'careers.png';

const CAREERS_LINK = 'https://apply.workable.com/neatleaf';

export const Careers: FC = () => {
  const handleOnCLick = () => {
    GoogleAnalyticsGA4.sendEvent({
      category: EMetricCategory.CAREERS,
      action: EMetricAction.CLICK,
      label: 'View Current Openings',
    });
  };

  return (
    <Layout backgroundColor={COLORS.white}>
      <Banner filename={careersSrc} />

      <Content>
        <Section>
          <Typography variant="h4" tag="h1" margin="0 0 24px 0">
            Careers
          </Typography>
          <Typography variant="h6" margin="0 0 24px 0">
            We are always eager to learn about talent interested in
            strengthening our team. Don&apos;t see your role listed? Reach out
            to us through the contact form or write us at career@neatleaf.com
          </Typography>

          <ViewCurrentOpenings
            color="white"
            target="_blank"
            text="View Current Openings"
            to={{ pathname: CAREERS_LINK }}
            variant="contained"
            onClick={handleOnCLick}
          />
        </Section>
      </Content>
    </Layout>
  );
};

const ViewCurrentOpenings = styled(Link)({
  marginTop: 56,
  width: 'fit-content',
});
