import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import styled from 'styled-components';

import { DEVICES } from 'shared/constants';
import { getS3AssetsUrl, preloadImageSources } from 'shared/utils';
import { Typography } from 'common';

export const PARALLAX_OBSERVER = 'parallax-observer';
const TYPOGRAPHY_OBSERVER = 'typography-observer';

const COPY = ['Full.', 'Canopy.', 'Coverage.'];

const useCanopyParallax = () => {
  const parallaxObserver = new IntersectionObserver(
    ([container]) => {
      const { intersectionRect } = container;
      const { height } = intersectionRect;

      // Out of viewport. Do nothing.
      if (height === 0) return;

      // Container is entering the viewport at the top
      container?.target?.classList.toggle(
        'unfixed',
        container.intersectionRatio > 0.33333
      );
    },
    // We want to capture the Content right when sticky position initiates
    { threshold: 1 / 3 }
  );

  useEffect(() => {
    const target = document.getElementById(PARALLAX_OBSERVER);

    if (target) {
      parallaxObserver.observe(target);
    }
  }, []);
};

const useTypographyParallax = () => {
  const typographyObserver = new IntersectionObserver(
    ([container]) => {
      const { top } = container.boundingClientRect;
      const { intersectionRatio } = container;

      // NOTE: an opportunity to improve it by making it dynamic
      document
        .querySelector('.transition-0')
        ?.classList.toggle(
          'show',
          (top > 0 && intersectionRatio >= 0.15) ||
            (top <= 0 && intersectionRatio <= 0.5)
        );
      document
        .querySelector('.transition-1')
        ?.classList.toggle('show', top <= 0 && intersectionRatio <= 0.5);

      document
        .querySelector('.transition-2')
        ?.classList.toggle('show', top <= 0 && intersectionRatio <= 0.45);
    },
    { threshold: Array.from({ length: 100 }, (_, i) => (i + 1) * 0.01) }
  );

  useEffect(() => {
    const target = document.getElementById(TYPOGRAPHY_OBSERVER);

    if (target) {
      typographyObserver.observe(target);
    }
  }, []);
};

const imageOverViewLabels = 'image-overview-labels.webp';
const imageSources: string[] = [imageOverViewLabels];
export const CanopyParallax: FC = () => {
  useCanopyParallax();
  useTypographyParallax();
  const preload = preloadImageSources(imageSources, true);
  return (
    <>
      <Helmet>
        {preload.map((props) => (
          <link key={props.href} {...props} />
        ))}
      </Helmet>
      <Container id={PARALLAX_OBSERVER}>
        <Wrapper id={TYPOGRAPHY_OBSERVER}>
          <Content>
            {COPY.map((copy, i) => (
              <TypographyWithTransition
                key={copy}
                color="white"
                variant="h3"
                className={`transition-${i}`}
              >
                {copy}
              </TypographyWithTransition>
            ))}
          </Content>
        </Wrapper>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 0;
  position: relative;
  background: url(${getS3AssetsUrl('image-overview-labels_768px.webp')});
  background-size: auto 100vw;
  background-repeat: repeat-y;

  // IMPORTANT: always make sure to check z-index values
  // for any and all components that are above this component
  // otherwise this "background" will overflow on top
  // Current dependant components:
  //    - Hero.tsx
  @media (max-width: 767px) {
    position: relative;
    overflow: hidden;

    ::before {
      content: '';
      position: fixed;
      top: 0;
      width: 100%;
      height: 120vh;
      background-position: inherit;
      background-image: inherit;
      background-size: inherit;
      background-attachment: inherit;
      background-repeat: inherit;
    }
  }

  @media ${DEVICES.sm} {
    background-attachment: fixed;
    background: url(${getS3AssetsUrl('image-overview-labels_1024px.webp')});
    height: 300vh;

    &.unfixed {
      background-attachment: scroll;
    }
  }

  @media ${DEVICES.md} {
    background: url(${getS3AssetsUrl('image-overview-labels_1440px.webp')});
  }

  @media ${DEVICES.lg} {
    background: url(${getS3AssetsUrl('image-overview-labels_2560px.webp')});
  }
`;

const Wrapper = styled.div`
  height: auto;

  @media ${DEVICES.sm} {
    // NOTE: Since 'Container' is 3x the screen height,
    // we want the text to appear in the center of
    // the 1st part of the screen and stop on center of
    // the 3rd part of the screen
    // so we take 2/3 of the screen height, with one third
    // taking up the middle screen and the other two
    // taking up half of first and third screens
    // which depend on vertical centering of the 'Container'
    height: calc(300vh / 3 * 2);
    // border: 5px solid red;
  }
`;

const Content = styled.div`
  position: sticky;
  top: 50%;
  height: auto;
  transform: translateY(-50%);
`;

const TypographyWithTransition = styled(Typography)`
  transition: opacity 0.3s ease;

  @media ${DEVICES.sm} {
    &[class*='transition-'] {
      opacity: 0;

      &.show {
        opacity: 1;
      }
    }
  }
`;
