import { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Typography } from 'common';
import { Header, HeaderColor } from 'components/Header';
import { DEVICES } from 'shared/constants';
import { PARALLAX_OBSERVER } from './CanopyParallax';
import { getS3AssetsUrl } from 'shared/utils';

const MAIN = 'main';
const HEADER = 'header';

const HeroVideo: FC = () => {
  const videoImage = getS3AssetsUrl('intro-video_1440px.webp');
  const videoSmall = getS3AssetsUrl('intro-video.webm');
  const videoLarge = getS3AssetsUrl('intro-video.mp4');
  return (
    <Video
      autoPlay
      loop
      preload="auto"
      muted
      playsInline
      style={{
        backgroundImage: `url(${videoImage})`,
      }}
    >
      <source src={videoSmall} type="video/webm" />
      <source src={videoLarge} type="video/mp4" />
      <p>
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that{' '}
        <a
          href="https://videojs.com/html5-video-support/"
          target="_blank"
          rel="noreferrer"
        >
          supports HTML5 video
        </a>
      </p>
    </Video>
  );
};

const useCanopyObserver = (callback: (isFixed: boolean) => void) => {
  const parallaxObserver = new IntersectionObserver(
    ([container]) => {
      const headerEl = document.getElementById(HEADER);

      if (
        container.intersectionRatio <= 0.34 &&
        container.boundingClientRect.top <= 0
      ) {
        callback(true);
      } else {
        callback(false);
      }
      headerEl?.classList.toggle(
        'fixed',
        container.intersectionRatio <= 0.34 &&
          container.boundingClientRect.top <= 0
      );
      headerEl?.classList.toggle(
        'open',
        container.intersectionRatio === 0 &&
          !container.isIntersecting &&
          container.boundingClientRect.top <= 0
      );
      headerEl?.classList.toggle(
        'close',
        container.intersectionRatio >= 0 &&
          container.intersectionRatio < 0.33 &&
          container.isIntersecting &&
          container.boundingClientRect.top <= 0
      );
    },
    { threshold: [0, 1 / 3] }
  );

  useEffect(() => {
    const main = document.getElementById(PARALLAX_OBSERVER);

    if (main) {
      parallaxObserver.observe(main);
    }
  }, []);
};

export const Hero: FC = () => {
  const [headerColor, setHeaderColor] = useState(HeaderColor.WHITE);

  useCanopyObserver((isFixed) => {
    if (isFixed) setHeaderColor(HeaderColor.BLACK);
    else setHeaderColor(HeaderColor.WHITE);
  });

  return (
    <MainContainer id={MAIN}>
      <HeaderContainer id={HEADER}>
        <Header color={headerColor} hasPadding={false} />
      </HeaderContainer>
      <HeroVideo />
      <HeroVideoOverlay />

      <HeroContainer>
        <CallToActionContainer id="cta-container">
          <Typography color="white" variant="h3" tag="h1" lineHeight="normal">
            The Neatleaf Spyder
          </Typography>
          <Typography color="white" variant="h5" tag="p" lineHeight="1.5">
            The plant expert that keeps an eye on every plant. <br />
            All day, every day.
          </Typography>
        </CallToActionContainer>
      </HeroContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: max-content 1fr;
  min-height: 100vh;
  width: 100%;

  // NOTE: This is a dependency for CanopyParallax.tsx
  // mobile implementation of a "background-attachmend: fix" 'hack'
  // because anything that's above CanopyParallax.tsx component
  // will have a background overflowing on top of it
  // that's just how position: fixed works
  @media (max-width: 767px) {
    z-index: 1;
  }
`;
const HeroContainer = styled.div`
  min-height: 100%;
  width: 100%;
`;
const CallToActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  padding: 26px 26px 85px;
  min-height: 100%;
  gap: 8px;

  @media ${DEVICES.sm} {
    justify-content: center;
    padding: 60px 85px;
  }
`;

// NOTE: Making header sticky in landing as per design specifications
// using 'fixed' property because the CallToActionContainer content
// is actually vertically centered from window perspective instead of
// container perspective
const HeaderContainer = styled.div`
  position: static;
  top: -1px;
  left: 0;
  z-index: 100;
  padding: 26px;
  width: 100%;
  background: rgba(255, 255, 255, 0);
  overflow: hidden;
  // NOTE: we only want background animated since
  // 'fixed' class is added with a default translate value
  // which would end up with a FOUC-like effect animating
  // from translate 0 to translate -100%
  transition: background 0.4s ease-in-out;

  // NOTE: We reset transition property to
  // ensure the animations are still rendered by the browser
  &.fixed {
    position: fixed;
    transform: translateY(-100%);
    background: rgba(255, 255, 255, 1);

    &.open {
      transition: transform 0.4s ease-in-out;
      transform: translateY(0);
    }

    &.close {
      transition: transform 0.4s ease-in-out;
      transform: translateY(-100%);
    }
  }

  @media ${DEVICES.sm} {
    padding: 20px 20px;
  }
`;

const videoPositioning = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -100;
  margin: auto;
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-size: cover;
  object-fit: cover;
`;

const HeroVideoOverlay = styled.div`
  ${videoPositioning}
  background: rgba(0, 0, 0, 0.35);
`;

const Video = styled.video`
  ${videoPositioning}
`;
