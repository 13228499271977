import { useMemo } from 'react';
import { useLocation } from 'react-router';

/**
 * Returns an object like representation of the current query parameters
 *
 * @returns {Record<string, string>} object like representation of the current query parameters
 */
export function useQueryParams(): Record<string, string> {
  const { search } = useLocation();

  return useMemo(() => {
    return Array.from(new URLSearchParams(search).entries()).reduce(
      (query, [key, value]) => {
        query[key] = value;
        return query;
      },
      {} as Record<string, string>
    );
  }, [search]);
}
