import { useEffect, useReducer, useRef } from 'react';

interface State<T> {
  data?: T;
  error?: Error;
  loading?: boolean;
}

type Cache<T> = { [url: string]: T };

// discriminated union type
type Action<T> =
  | { type: 'loading' }
  | { type: 'fetched'; payload: T }
  | { type: 'error'; payload: Error };

/**
 * Copied from https://usehooks-ts.com/react-hook/use-fetch
 * with a minor change to also expose a loading state.
 * @param {string} url The resource to fetch
 * @param {RequestInit | undefined} options Possible custom options to pass to the request
 * @returns {State} {State}
 */
export function useFetch<T = unknown>(
  url: string,
  options?: RequestInit
): State<T> {
  const cache = useRef<Cache<T>>({});

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false);

  const initialState: State<T> = {
    error: undefined,
    data: undefined,
    loading: false,
  };

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'loading':
        return { ...initialState, loading: true };
      case 'fetched':
        return { ...initialState, loading: false, data: action.payload };
      case 'error':
        return { ...initialState, loading: false, error: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    cancelRequest.current = false;

    const fetchData = async () => {
      dispatch({ type: 'loading' });

      // If a cache exists for this url, return it
      if (cache.current[url]) {
        dispatch({ type: 'fetched', payload: cache.current[url] });
        return;
      }

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        let data;
        if (response.headers.get('Content-Type') === 'application/json') {
          data = await response.json();
        } else {
          data = await response.text();
        }

        cache.current[url] = data as T;

        if (cancelRequest.current) {
          return;
        }

        dispatch({ type: 'fetched', payload: data as T });
      } catch (error) {
        if (cancelRequest.current) {
          return;
        }

        dispatch({ type: 'error', payload: error as Error });
      }
    };

    void fetchData();

    // Use the cleanup function for avoiding a possibly...
    // ...state update after the component was unmounted
    return () => {
      cancelRequest.current = true;
    };
  }, [url]);

  return state;
}
