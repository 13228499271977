import { FC } from 'react';
import styled from 'styled-components';

import { Typography } from 'common';
import { ReactComponent as Logo } from 'assets/svgs/logo.svg';
import { ReactComponent as MaintenanceLogo } from 'assets/svgs/maintenance.svg';
import { useScreenSize } from 'hooks/useScreenSize';
import { IResponsiveLayoutProps } from 'shared/types';
import { COLORS } from 'shared/constants';
import { HeaderColor } from 'components/Header';

const Maintenance: FC = () => {
  const { isMobile } = useScreenSize();

  return (
    <MaintenaceContainer $isMobile={isMobile}>
      <Header>
        <Logo color={HeaderColor.BLACK} />
      </Header>

      <Main $isMobile={isMobile}>
        <StyledMaintenanceLogo isMobile={isMobile} />

        <Content $isMobile={isMobile}>
          <Title $isMobile={isMobile}>Under maintenance</Title>
          <Description variant="body2" $isMobile={isMobile}>
            We are working on somethings. Please check back later.
          </Description>
        </Content>
      </Main>
    </MaintenaceContainer>
  );
};

const MaintenaceContainer = styled.div<IResponsiveLayoutProps>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: ${({ $isMobile: isMobile }) => (isMobile ? 24 : 48)}px;
`;

const Header = styled.div``;

const Main = styled.div<IResponsiveLayoutProps>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ $isMobile: isMobile }) => (isMobile ? 'column' : 'row')};
`;

const StyledMaintenanceLogo = styled(MaintenanceLogo)<IResponsiveLayoutProps>`
  width: ${({ isMobile }) => (isMobile ? 240 : 400)}px;
  height: ${({ isMobile }) => (isMobile ? 240 : 400)}px;
`;

const Content = styled.div<IResponsiveLayoutProps>`
  display: flex;
  align-items: ${({ $isMobile: isMobile }) =>
    isMobile ? 'center' : 'flex-start'};
  margin-left: ${({ $isMobile: isMobile }) => (isMobile ? 0 : 80)}px;
  margin-top: ${({ $isMobile: isMobile }) => (isMobile ? 40 : 0)}px;
  flex-direction: column;
  max-width: 380px;
`;

const Title = styled(Typography)<IResponsiveLayoutProps>`
  font-size: ${({ $isMobile: isMobile }) => (isMobile ? 24 : 30)}px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const Description = styled(Typography)<IResponsiveLayoutProps>`
  font-size: ${({ $isMobile: isMobile }) => (isMobile ? 14 : 16)}px;
  font-weight: 400;
  color: ${COLORS.dark7};
`;

export default Maintenance;
