import 'katex/dist/katex.min.css';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import { Components } from 'react-markdown/lib/ast-to-react';
import remarkMath from 'remark-math';
import remarkGfm from 'remark-gfm';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import { Typography } from 'common';
import { SIZES } from 'shared/constants';
import { getS3AssetsUrl } from 'shared/utils';
import styled from 'styled-components';

export const Markdown = ({ children }: ReactMarkdownOptions) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkMath, remarkGfm]}
      rehypePlugins={[rehypeKatex, rehypeRaw]}
      components={COMPONENTS}
    >
      {children}
    </ReactMarkdown>
  );
};

const COMPONENTS: Components = {
  h1: ({ children }: ReactMarkdownProps) => (
    <Typography
      variant="h6"
      tag="h1"
      fontWeight="700"
      padding={`${SIZES.small}px 0`}
    >
      {children}
    </Typography>
  ),
  h2: ({ children }: ReactMarkdownProps) => (
    <Typography
      variant="body1"
      tag="h2"
      fontWeight="700"
      padding={`${SIZES.small}px 0`}
    >
      {children}
    </Typography>
  ),
  p: ({ children, node }: ReactMarkdownProps) => {
    if (node.children.some(({ type }) => type === 'text')) {
      return (
        <Typography variant="body1" tag="p" padding={`${SIZES.small}px 0`}>
          {children}
        </Typography>
      );
    }
    return <>{children}</>;
  },
  li: ({ children }: ReactMarkdownProps) => (
    <Typography variant="body1" tag="li" padding={`${SIZES.xSmall}px 0`}>
      {children}
    </Typography>
  ),
  i: ({ children }: ReactMarkdownProps) => (
    <Typography
      variant="body2"
      tag="i"
      color="black50"
      textTransform="uppercase"
    >
      {children}
    </Typography>
  ),
  figure: ({ children }: ReactMarkdownProps) => (
    <Typography
      variant="body2"
      tag="figure"
      fontStyle="italic"
      textAlign="center"
      padding={`${SIZES.xSmall}px 0`}
    >
      {children}
    </Typography>
  ),
  img: ({ node }: ReactMarkdownProps) =>
    node.properties?.src ? (
      <Img
        {...node.properties}
        src={getS3AssetsUrl(node.properties.src as string)}
      />
    ) : null,
};

const Img = styled.img`
  max-width: 100%;
  object-fit: cover;
  padding-top: ${SIZES.small}px;
  padding-bottom: ${SIZES.small}px;
`;
