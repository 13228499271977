import config from 'config';
import {
  EXTENDED_RESOLUTIONS_RANGES,
  RESOLUTION_RANGES,
  TYPES,
} from 'shared/constants';
import { TPreloadImageSource, TResolutionRange } from './types';
import { basename, extname } from 'path-browserify';

/**
 * Returns the URL of an S3 asset given the file name.
 *
 * @param {string} filename - The name of the file.
 * @returns {string} The URL of the S3 asset.
 * example - on dev environment with fileName: intro-video.mp4
 * returns:
 * https://neatleaf-dev-landing-page-assets.s3.us-west-2.amazonaws.com/intro-video.mp4
 */
export function getS3AssetsUrl(filename: string): string {
  return new URL(filename, config.S3_ASSEETSS_URL).href;
}

export const isEmailValid = (email: string): boolean =>
  // eslint-disable-next-line no-useless-escape, max-len
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

// NOTE: this is actually dangerous,
// it is only US format, but since
// we're operating in the US, leaving it be
export const isPhoneNumberValid = (number: string): boolean => {
  return number.length === 10 && /^[0-9]+$/.test(number);
};

/**
 * Preloads image sources.
 *
 * @param {string[]} imageSources - The array of image sources.
 * @param {boolean} useExtendedResolutions - Whether to use extended resolutions.
 * @returns {TPreloadImageSource[]} The array of preloaded image sources.
 */
export function preloadImageSources(
  imageSources: string[],
  useExtendedResolutions = false
): TPreloadImageSource[] {
  let resolutionRanges = RESOLUTION_RANGES;
  if (useExtendedResolutions) resolutionRanges = EXTENDED_RESOLUTIONS_RANGES;
  return imageSources
    .map((filename) => {
      const extension = extname(filename);
      const baseFilename = basename(filename, extension);
      return TYPES.map((type) =>
        resolutionRanges.map(({ media, resolution }) => ({
          as: 'image',
          rel: 'preload',
          media,
          href: getS3AssetsUrl(`/${baseFilename}_${resolution}px.${type}`),
        }))
      );
    })
    .flat()
    .flat();
}

/**
 * Generates an array of resolution ranges based on the input resolutions.
 *
 * @param {number[]} resolutions - An array of resolution values.
 * @returns {TResolutionRange[]} - An array of objects representing resolution ranges and corresponding media queries.
 * Ordering matters
 * given input resolutions of [768, 1024, 1440, 2560]
 * example output:
 * [ { resolution: 768, media: '(max-width: 768px)' },
 *   { resolution: 1024, media: '(min-width: 769px) and (max-width: 1024px)' },
 *   { resolution: 1440, media: '(min-width: 1025px) and (max-width: 1440px)' },
 *   { resolution: 2560, media: '(min-width: 1441px)' } ]
 */
export function generateResolutionRanges(
  resolutions: number[]
): TResolutionRange[] {
  // Ordering matters
  const sortedResolutions = [...resolutions].sort((a, b) => a - b);

  return sortedResolutions.reduce(
    (acc: TResolutionRange[], current, index, resolutions) => {
      let mediaQuery = '';
      const previous = resolutions[index - 1];
      if (index === 0) {
        // First resolution
        mediaQuery = `(max-width: ${current}px)`;
      } else if (index === resolutions.length - 1) {
        // Last resolution
        mediaQuery = `(min-width: ${previous + 1}px)`;
      } else {
        // Middle resolutions
        mediaQuery = `(min-width: ${
          previous + 1
        }px) and (max-width: ${current}px)`;
      }

      acc.push({ resolution: current, media: mediaQuery });
      return acc;
    },
    []
  );
}
