import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GoogleAnalyticsGA4 } from 'shared/analytics/GoogleAnalyticsGA4';
import { EMetricAction, EMetricCategory } from 'shared/analytics/contracts';

export const RouteChangeTracker: FC = () => {
  const location = useLocation();

  useEffect(() => {
    const event = {
      category: EMetricCategory.NAVIGATION,
      action: EMetricAction.PAGE_LOAD,
      label: location.pathname,
    };

    GoogleAnalyticsGA4.sendEvent(event);
    GoogleAnalyticsGA4.sendPageView(location.pathname);
  }, [location.pathname]);

  return null;
};
