import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  RadioGroup,
} from '@material-ui/core';
import { ChangeEvent, FC, FormEventHandler, useState } from 'react';
import styled from 'styled-components';

import { Button, Checkbox, Label, Radio, TextInput } from 'common';
import {
  COLORS,
  CULTIVATION_CANOPY,
  CULTIVATION_FACILITY,
  FormType,
} from 'shared/constants';
import axios from 'axios';
import config from 'config';
import { isEmailValid, isPhoneNumberValid } from 'shared/utils';
import { AlertMessage, AlertType } from 'common/AlertMessage';
import { GoogleAnalyticsGA4 } from 'shared/analytics/GoogleAnalyticsGA4';
import { EMetricAction, EMetricCategory } from 'shared/analytics/contracts';

type InputChangeEvent = ChangeEvent<HTMLInputElement>;

const DEFAULT_FORM_STATE = {
  submitting: false,
  success: '',
  error: '',
  validation: {
    fullName: '',
    company: '',
    email: '',
    phone: '',
    cultivationFacilities: '',
    cultivationCanopy: '',
    formType: '',
  },
};

export const Form: FC = () => {
  const [formState, setFormState] = useState(DEFAULT_FORM_STATE);

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');

  const [cultivationFacilities, setCultivationFacilities] = useState<string[]>(
    []
  );
  const [cultivationCanopy, setCultivationCanopy] = useState('');

  const handleChangeCultivationFacility = (
    event: InputChangeEvent,
    checked: boolean
  ) => {
    if (checked) {
      setCultivationFacilities([...cultivationFacilities, event.target.name]);
    } else {
      setCultivationFacilities(
        cultivationFacilities.filter(
          (facility) => facility !== event.target.name
        )
      );
    }
  };

  const handleFormValidation = async () => {
    let validation = { ...DEFAULT_FORM_STATE.validation };

    if (typeof fullName !== 'string' || !fullName || fullName.length <= 3) {
      validation = {
        ...validation,
        fullName: 'Please enter your full name',
      };
    }

    if (typeof company !== 'string' || !company || company.length <= 3) {
      validation = {
        ...validation,
        company: 'Please enter company name',
      };
    }

    if (typeof email !== 'string' || !email) {
      validation = {
        ...validation,
        email: 'Please enter your email',
      };
    } else if (!isEmailValid(email)) {
      validation = {
        ...validation,
        email: 'Please enter valid email',
      };
    }

    if (typeof phone !== 'string' || !phone) {
      validation = {
        ...validation,
        phone: 'Please enter your phone number',
      };
    } else if (!isPhoneNumberValid(phone)) {
      validation = {
        ...validation,
        phone: 'Please enter valid phone number',
      };
    }

    if (!cultivationFacilities.length) {
      validation = {
        ...validation,
        cultivationFacilities: 'Please select at least one type of cultivation',
      };
    }

    if (!cultivationCanopy) {
      validation = {
        ...validation,
        cultivationCanopy: 'Please select your cultivation canopy size',
      };
    }

    const isValid = Object.values(validation).every((field) => !field);

    setFormState({
      ...DEFAULT_FORM_STATE,
      error: !isValid ? 'Please enter valid information' : '',
      validation,
    });

    return isValid;
  };

  const handleSubmit: FormEventHandler = async (event) => {
    event.preventDefault();
    GoogleAnalyticsGA4.sendEvent({
      category: EMetricCategory.SCHEDULE_DEMO,
      action: EMetricAction.SUBMIT,
      label: 'Schedule Demo',
      dimension1: email,
      dimension2: company,
    });
    const isValid = await handleFormValidation();

    if (!isValid) {
      return;
    }

    setFormState({
      ...DEFAULT_FORM_STATE,
      submitting: true,
    });

    const message = {
      fullName,
      email,
      phone,
      company,
      cultivationCanopy,
      cultivationFacilities,
      formType: FormType.SCHEDULE_DEMO,
    };

    const form = {
      name: fullName,
      email,
      message: JSON.stringify(message, null, 2),
      isSigningUpForNewsLetter: false,
      formType: FormType.SCHEDULE_DEMO,
    };

    try {
      await axios.post(config.MAIL_SUBMIT_API, form, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      setFormState({
        ...DEFAULT_FORM_STATE,
        success:
          'Your submission has been received! Thank you for contacting us.',
      });
    } catch (error) {
      setFormState({
        ...DEFAULT_FORM_STATE,
        error:
          'Your submission has not been delivered, please try again later.',
      });
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <TextInput
        label="Full name"
        value={fullName}
        onChange={setFullName}
        error={formState.validation.fullName}
      />
      <TextInput
        label="Company"
        value={company}
        onChange={setCompany}
        error={formState.validation.company}
      />
      <TextInput
        label="Email"
        type="email"
        value={email}
        onChange={setEmail}
        error={formState.validation.email}
      />
      <TextInput
        label="Phone"
        type="tel"
        value={phone}
        onChange={setPhone}
        error={formState.validation.phone}
      />

      <FormControl>
        <Label>Types of cultivation</Label>

        {CULTIVATION_FACILITY.map((facility: string) => (
          <FormControlLabel
            key={facility}
            value={facility}
            control={
              <Checkbox
                name={facility}
                checked={cultivationFacilities.includes(facility)}
                onChange={handleChangeCultivationFacility}
              />
            }
            label={facility}
          />
        ))}

        {formState.validation.cultivationFacilities ? (
          <AlertMessage type={AlertType.ERROR}>
            {formState.validation.cultivationFacilities}
          </AlertMessage>
        ) : null}
      </FormControl>

      <FormControl>
        <Label>Total cultivation canopy in feet</Label>
        <RadioGroup
          name="cultivation-canopy"
          value={cultivationCanopy}
          onChange={(event: InputChangeEvent) =>
            setCultivationCanopy(event.target.value)
          }
        >
          {CULTIVATION_CANOPY.map((value: string) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio checked={value === cultivationCanopy} />}
              label={value}
            />
          ))}
        </RadioGroup>
        {formState.validation.cultivationCanopy ? (
          <AlertMessage type={AlertType.ERROR}>
            {formState.validation.cultivationCanopy}
          </AlertMessage>
        ) : null}
      </FormControl>

      <StyledFooter>
        <Button
          type="submit"
          color="primary"
          disabled={formState.submitting || !!formState.success}
        >
          Send
        </Button>

        {formState.submitting ? <CircularProgress color="inherit" /> : null}
      </StyledFooter>
      {formState.error && (
        <AlertMessage type={AlertType.ERROR}>{formState.error}</AlertMessage>
      )}
      {formState.success && (
        <AlertMessage type={AlertType.SUCCESS}>
          {formState.success}
        </AlertMessage>
      )}
    </StyledForm>
  );
};

const StyledForm = styled.form`
  display: grid;
  justify-items: start;
  gap: 18px;
`;

const StyledFooter = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 18px;
  min-height: 43px;
  color: ${COLORS.orange3};
`;
