import { FC, MouseEventHandler, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { Link } from 'common';
import { ROUTES } from 'shared/constants';

import { ReactComponent as NeatleafMediumLogo } from 'assets/svgs/neatleaf-medium-logo.svg';
import { ReactComponent as HamburgerIcon } from 'assets/svgs/hamburger.svg';

const menuItems = [
  {
    text: 'Home',
    to: ROUTES.LANDING,
  },
  {
    text: 'About Us',
    to: ROUTES.ABOUT_US,
  },
  {
    text: 'Careers',
    to: ROUTES.CAREERS,
  },
  {
    text: 'Contact Us',
    to: ROUTES.CONTACT_US,
  },
  {
    text: 'Blog',
    to: ROUTES.BLOG,
  },
  {
    text: 'Newsroom',
    to: ROUTES.NEWSROOM,
  },
  {
    text: 'Login',
    target: '_blank',
    to: { pathname: ROUTES.LOGIN },
  },
];

interface HamburgerMenuProps {
  color: 'black' | 'white';
  current: string;
}

export const HamburgerMenu: FC<HamburgerMenuProps> = ({ color, current }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenMenu = () => {
    setIsOpen(true);
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  const handleClickMenu: MouseEventHandler = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      <IconButton role="button" onClick={handleOpenMenu}>
        <HamburgerIcon color={color} />
      </IconButton>

      {createPortal(
        <>
          <MenuContainer onClick={handleCloseMenu} $isOpen={isOpen} />

          <Menu $isOpen={isOpen} onClick={handleClickMenu}>
            <NeatleafLogo />

            {menuItems.map((menuItem) => (
              <MenuItem key={menuItem.text}>
                <MenuLink
                  color={current === menuItem.to ? 'orange3' : 'black'}
                  target={menuItem.target}
                  $isActive={current === menuItem.to}
                  text={menuItem.text}
                  to={menuItem.to}
                />
              </MenuItem>
            ))}
          </Menu>
        </>,
        document.body
      )}
    </>
  );
};

interface MenuProps {
  $isOpen: boolean;
}

const MenuContainer = styled.div<MenuProps>(({ $isOpen }) => ({
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: $isOpen ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  transition: 'background-color 0.25s ease-in-out',
  zIndex: 1000,
  pointerEvents: $isOpen ? 'auto' : 'none',
}));

const IconButton = styled.div({
  background: 'transparent',
  cursor: 'pointer',
});

const Menu = styled.ul<MenuProps>(({ $isOpen }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  width: 300,
  height: '100vh',
  background: 'white',
  margin: 0,
  listStyle: 'none',
  padding: 16,
  zIndex: 1000,
  transform: $isOpen ? 'translateX(0)' : 'translateX(-100%)',
  transition: 'transform 0.25s ease-in-out',
}));

const MenuItem = styled.li({
  width: '100%',
});

const NeatleafLogo = styled(NeatleafMediumLogo)({
  marginBottom: 52,
});

interface IMenuLinkProps {
  $isActive: boolean;
}

const MenuLink = styled(Link)<IMenuLinkProps>(({ $isActive }) => ({
  display: 'inline-block',
  fontSize: 16,
  fontWeight: $isActive ? 700 : 400,
  background: $isActive ? 'rgba(244, 142, 66, 0.07)' : 'transparent',
  width: '100%',
  padding: '12px 20px',
  borderRadius: 60,
}));
