import { Typography } from 'common';
import { COLORS, DEVICES } from 'shared/constants';
import styled from 'styled-components';

const CheckIcon = () => (
  <svg viewBox="0 0 25 25" fill="none">
    <path
      // eslint-disable-next-line max-len
      d="M12.0218 2.36035C6.49892 2.36035 2.0166 6.84267 2.0166 12.3655C2.0166 17.8884 6.49892 22.3707 12.0218 22.3707C17.5446 22.3707 22.0269 17.8884 22.0269 12.3655C22.0269 6.84267 17.5446 2.36035 12.0218 2.36035ZM12.0218 20.3697C7.60949 20.3697 4.01764 16.7778 4.01764 12.3655C4.01764 7.95324 7.60949 4.36139 12.0218 4.36139C16.434 4.36139 20.0259 7.95324 20.0259 12.3655C20.0259 16.7778 16.434 20.3697 12.0218 20.3697ZM15.9038 8.6536L10.0207 14.5366L8.13976 12.6557C7.74956 12.2655 7.11924 12.2655 6.72904 12.6557C6.33883 13.0459 6.33883 13.6762 6.72904 14.0664L9.32037 16.6577C9.71058 17.0479 10.3409 17.0479 10.7311 16.6577L17.3245 10.0643C17.7147 9.67413 17.7147 9.0438 17.3245 8.6536C16.9343 8.2634 16.294 8.2634 15.9038 8.6536Z"
      fill="#CFCFCF"
    />
  </svg>
);
const CheckIconInverted = () => (
  <svg viewBox="0 0 25 25" fill="none">
    <path
      // eslint-disable-next-line max-len
      d="M12.1624 2.59082C6.63954 2.59082 2.15723 7.07314 2.15723 12.596C2.15723 18.1188 6.63954 22.6012 12.1624 22.6012C17.6852 22.6012 22.1676 18.1188 22.1676 12.596C22.1676 7.07314 17.6852 2.59082 12.1624 2.59082ZM9.45099 16.8882L5.85914 13.2964C5.46894 12.9061 5.46894 12.2758 5.85914 11.8856C6.24934 11.4954 6.87967 11.4954 7.26987 11.8856L10.1614 14.7671L17.0449 7.88355C17.4351 7.49335 18.0654 7.49335 18.4556 7.88355C18.8458 8.27376 18.8458 8.90408 18.4556 9.29428L10.8617 16.8882C10.4815 17.2784 9.8412 17.2784 9.45099 16.8882Z"
      fill="#F48E42"
    />
  </svg>
);

const COPY = {
  wyn: {
    header: 'What you need',
    uspList: [
      'Indoor or Greenhouse Facility',
      'Wall Power Outlet',
      'Internet Access',
    ],
  },
  wwp: {
    header: 'What we provide',
    uspList: [
      'Installation',
      'Spyder Robot',
      '24/7 Monitoring and Alerts',
      'Actionable Insights',
    ],
  },
};

export const USP = () => {
  return (
    <Container>
      <Typography variant="h4" tag="h2" margin="0 0 48px 0">
        What do I need to start?
      </Typography>
      <Wrapper>
        <Card variant="white">
          <Typography
            variant="h6"
            tag="h3"
            margin="0 0 32px 0"
            fontWeight="700"
          >
            {COPY.wyn.header}
          </Typography>
          <List>
            {COPY.wyn.uspList.map((item) => (
              <ListItem key={item}>
                <StyledCheckIcon>
                  <CheckIcon />
                </StyledCheckIcon>
                <Typography variant="body1" tag="p" fontWeight="400">
                  {item}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Card>

        <Card variant="black">
          <Typography
            variant="h6"
            tag="h3"
            margin="0 0 32px 0"
            fontWeight="700"
          >
            {COPY.wwp.header}
          </Typography>

          <List>
            {COPY.wwp.uspList.map((item) => (
              <ListItem key={item}>
                <StyledCheckIcon>
                  <CheckIconInverted />
                </StyledCheckIcon>
                <Typography variant="body1" tag="p" fontWeight="400">
                  {item}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Card>
      </Wrapper>
    </Container>
  );
};

const Container = styled.section`
  margin: 0 auto;
  padding: 0 0 80px;
  width: 100%;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  padding: 0 20px;

  @media ${DEVICES.sm} {
    flex-direction: row;
    gap: 0;
  }
`;

const Card = styled.div<{ variant: 'white' | 'black' }>`
  padding: 30px;
  background: ${(props) =>
    props.variant === 'white' ? COLORS.white : COLORS.dark2};
  color: ${(props) =>
    props.variant === 'white' ? COLORS.black : COLORS.white};
  border-radius: 20px;
  box-shadow: 0px 7.503876209259033px 37.519378662109375px 0px
    rgba(0, 0, 0, 0.1);
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: left;

  & + & {
    margin-top: 18px;
  }
`;

const StyledCheckIcon = styled.div`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;
