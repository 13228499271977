import { FC } from 'react';

import Routes from './Routes';
import { GoogleAnalyticsGA4 } from 'shared/analytics/GoogleAnalyticsGA4';
import config from 'config';
new GoogleAnalyticsGA4(config.google?.prod_ga4_tracking_id);
const App: FC = () => {
  return (
    <div className="App">
      <Routes />
    </div>
  );
};

export default App;
