import { FC } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES } from 'shared/constants';
import { Landing } from 'containers/Landing/Landing';
import { AboutUs } from 'containers/AboutUs/AboutUs';
import { Careers } from 'containers/Careers/Careers';
import { ScheduleDemo } from 'containers/ScheduleDemo/ScheduleDemo';
import ContactUs from 'containers/ContactUs/ContactUs';
import Privacy from 'containers/Privacy/Privacy';
import Maintenance from 'containers/Maintenance/Maintenance';
import { RouteChangeTracker } from 'common/RouteChangeTracker';
import { Blog } from 'containers/Blog/Blog';
import { Post } from 'containers/Blog/Post';
import { Newsroom } from 'containers/Newsroom/Newsroom';
import Promotion from 'containers/Promotion/Promotion';

const Routes: FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={ROUTES.LANDING} component={Landing} />
        <Route exact path={ROUTES.ABOUT_US} component={AboutUs} />
        <Route exact path={ROUTES.CAREERS} component={Careers} />
        <Route exact path={ROUTES.CONTACT_US} component={ContactUs} />
        <Route exact path={ROUTES.PROMOTION} component={Promotion} />
        <Route exact path={ROUTES.SCHEDULE_DEMO} component={ScheduleDemo} />
        <Route exact path={ROUTES.PRIVACY} component={Privacy} />
        <Route exact path={ROUTES.MAINTENANCE} component={Maintenance} />
        <Route exact path={ROUTES.BLOG} component={Blog} />
        <Route exact path={`${ROUTES.BLOG}/:postId`} component={Post} />
        <Route exact path={ROUTES.NEWSROOM} component={Newsroom} />
        <Redirect to={ROUTES.LANDING} />
      </Switch>
      <RouteChangeTracker />
    </BrowserRouter>
  );
};

export default Routes;
