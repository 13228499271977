import { FC } from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { Link, Typography } from 'common';
import { ROUTES } from 'shared/constants';
import { IResponsiveLayoutProps } from 'shared/types';
import { useScreenSize } from 'hooks';

import { ReactComponent as GrayLogo } from 'assets/svgs/gray-logo.svg';

export const Footer: FC = () => {
  const { isMobile } = useScreenSize();

  return (
    <Container $isMobile={isMobile}>
      <Main $isMobile={isMobile}>
        <LogoContainer $isMobile={isMobile}>
          <RouterLink to={ROUTES.LANDING}>
            <GrayLogo />
          </RouterLink>
        </LogoContainer>

        <LinkContainer $isMobile={isMobile}>
          <LinkGroup>
            <Link color="hb1" text="Schedule Demo" to={ROUTES.SCHEDULE_DEMO} />
            <Link color="hb1" text="About Us" to={ROUTES.ABOUT_US} />
            <Link color="hb1" text="Contact Us" to={ROUTES.CONTACT_US} />
          </LinkGroup>
          <LinkGroup>
            <Link color="hb1" text="Privacy" to={ROUTES.PRIVACY} />
            <Link color="hb1" text="Careers" to={ROUTES.CAREERS} />
            <Link color="hb1" text="Blog" to={ROUTES.BLOG} />
          </LinkGroup>
        </LinkContainer>
      </Main>

      <FooterText $isMobile={isMobile} variant="body2" color="grey4">
        {`© 2020-${new Date().getFullYear()} Neatleaf.com, Inc. or its affiliates. All rights
        reserved.`}
      </FooterText>
    </Container>
  );
};

const Container = styled.footer<IResponsiveLayoutProps>(
  ({ $isMobile: isMobile }) => ({
    padding: isMobile ? '50px' : '100px 134px',
    position: isMobile ? 'relative' : undefined,
    display: 'flex',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    alignItems: 'space-between',
    flexDirection: 'column',
    width: '100%',
    background: 'white',
    marginTop: 'auto',
  })
);

const Main = styled.div<IResponsiveLayoutProps>(({ $isMobile: isMobile }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: isMobile ? 'column' : 'row',
  marginBottom: isMobile ? 40 : 0,
}));

const LogoContainer = styled.div<IResponsiveLayoutProps>`
  ${({ $isMobile: isMobile }) =>
    isMobile ? 'margin-bottom: 40px; text-align: center;' : ''}
`;

const LinkContainer = styled.div<IResponsiveLayoutProps>(
  ({ $isMobile: isMobile }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    minWidth: isMobile ? 'unset' : 300,
  })
);

const LinkGroup = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
});

const FooterText = styled(Typography)<IResponsiveLayoutProps>(
  ({ $isMobile: isMobile }) => ({
    textAlign: isMobile ? 'center' : 'left',
  })
);
