import { FC } from 'react';

import styled from 'styled-components';

import { Typography } from 'common';
import { Form } from 'components/ScheduleDemo/Form';
import { useScrollToTop } from 'hooks';
import { USP } from 'components/ScheduleDemo/USP';

import Layout, { Banner, Content, Section } from 'common/Layout';
import { DEVICES } from 'shared/constants';

const scheduleDemoSrc = 'order-now.png';

interface IStep {
  step: string;
  detail: string;
}
const stepList: IStep[] = [
  {
    step: 'Contact us',
    detail: 'Fill out the form on this page.',
  },
  {
    step: 'Pre-installation meeting',
    detail:
      'We collect all the information needed to prepare your space for the installation.',
  },
  {
    step: 'Installation',
    detail:
      'Watch us install the system at your facility within a couple of hours.',
  },
  {
    step: 'Enjoy',
    detail:
      'Forget about the system and enjoy the data and the insights which it provides.',
  },
];
export const ScheduleDemo: FC = () => {
  useScrollToTop();

  return (
    <Layout>
      <Banner filename={scheduleDemoSrc} />

      <Content>
        <ScheduleDemoSection>
          <Typography variant="h4" tag="h1" margin="0 0 24px 0">
            Fill out the form - Grow with confidence and stop the guesswork
          </Typography>
          <Typography variant="h6" margin="0 0 24px 0">
            Experience the Spyder system at no-risk: free installation and
            without initial committment.
          </Typography>
          <Typography variant="h6" margin="0 0 24px 0">
            No upfront cost and an incredibly low service fee make this system a
            no-brainer.
          </Typography>
        </ScheduleDemoSection>

        <OrderStepsSection>
          <Typography
            variant="h6"
            tag="h2"
            fontWeight="600"
            margin="0 0 24px 0"
          >
            Get your Spyder in just a few easy steps:
          </Typography>
          {stepList.map((item, index) => (
            <>
              <Typography
                variant="h6"
                key={item.step}
                margin="0 0 8px 16px"
                fontStyle="italic"
              >
                {index + 1}. {item.step}
              </Typography>
              <Typography variant="h6" key={item.detail} margin="0 0 24px 0">
                {item.detail}
              </Typography>
            </>
          ))}
        </OrderStepsSection>
        <OrderFormSection>
          <Form />
        </OrderFormSection>
      </Content>

      <USP />
    </Layout>
  );
};

const ScheduleDemoSection = styled(Section)`
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;

  @media ${DEVICES.md} {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
  }
`;

const OrderStepsSection = styled(Section)`
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;

  @media ${DEVICES.md} {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
  }
`;

const OrderFormSection = styled(Section)`
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;

  @media ${DEVICES.md} {
    grid-column: 2 / span 1;
    grid-row: 1 / span 2;
  }
`;
