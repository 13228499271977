import { FC, useState } from 'react';
import { Helmet } from 'react-helmet';

import styled from 'styled-components';

import { ButtonSliders } from 'components/ButtonSliders';
import { getS3AssetsUrl, preloadImageSources } from 'shared/utils';
import { PanelGroup } from './PanelGroup';
import ResponsiveImg from 'components/ResponsiveImg';
import { TSliderItem } from 'shared/types';
import { RESOLUTION_RANGES } from 'shared/constants';

/* eslint-disable max-len */

const dashboardHeatmap = 'dashboard-heatmap.png';
const dashboardHeatmapMeta = 'dashboard-heatmap-meta_1440px.webp';
const dashboardImageFeed = 'dashboard-image-feed.png';
const dashboardImageFeedMeta = 'dashboard-image-feed-meta_1440px.webp';
const dashboardLineChart = 'dashboard-linechart.png';
const dashboardLineChartMeta = 'dashboard-linechart-meta_1440px.webp';
const dashboardZoneOverview = 'dashboard-zone-overview.png';
const dashboardZoneOverviewMeta = 'dashboard-zone-overview-meta_1440px.webp';
const imageSources = [
  { imageSrc: dashboardZoneOverview, metaImageSrc: dashboardZoneOverviewMeta },
  { imageSrc: dashboardImageFeed, metaImageSrc: dashboardImageFeedMeta },
  { imageSrc: dashboardHeatmap, metaImageSrc: dashboardHeatmapMeta },
  { imageSrc: dashboardLineChart, metaImageSrc: dashboardLineChartMeta },
];

const sliders: TSliderItem[] = [
  {
    button: 'Zone overview',
    description:
      'Our dashboard shows you the zone status at a glance: it summarizes the conditions as well as the trend and directs your attention to where it is needed most.',
  },
  {
    button: 'Image feed',
    description:
      'Look at the full canopy at once or zoom in to inspect a specific plant in more detail. Watch how the plant grows, identify systematic issues, or track how stress symptoms come and go.',
  },
  {
    button: 'Heatmaps',
    description:
      'The dashboard offers a comprehensive spatial understanding of your cultivation environment. Identify micro-climates and systematic issues in your zone and ensure consistency across your canopy.',
  },
  {
    button: 'Line chart',
    description:
      'Compare your cultivation conditions over time and relate them to peaks in plant stress and other observations.',
  },
];

export const NeatleafDashboard: FC = () => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const { imageSrc, metaImageSrc } = imageSources[sliderIndex];

  const preload = preloadImageSources(
    imageSources.map(({ imageSrc }) => imageSrc)
  );
  const preloadMeta = imageSources
    .map(({ metaImageSrc }) =>
      RESOLUTION_RANGES.map(({ media }) => ({
        as: 'image',
        rel: 'preload',
        media: media,
        href: getS3AssetsUrl(metaImageSrc),
      }))
    )
    .flat();

  return (
    <>
      <Helmet>
        {preload.map((props) => (
          <link key={props.href} {...props} />
        ))}
        {preloadMeta.map((props) => (
          <link key={props.href} {...props} />
        ))}
      </Helmet>
      <StyledPanelGroup
        bgImg={metaImageSrc}
        panels={[
          <ResponsiveImg
            key="left"
            filename={imageSrc}
            objectPosition="left"
          />,
          <ButtonSliders
            key="right"
            current={sliderIndex}
            items={sliders}
            title="The Neatleaf Dashboard"
            onChange={setSliderIndex}
          />,
        ]}
      />
    </>
  );
};

const StyledPanelGroup = styled(PanelGroup)<{ bgImg: string }>`
  position: relative;
  z-index: 0;

  &::before {
    position: absolute;
    inset: 0 0 0 0;
    z-index: -1;
    opacity: 0.6;
    content: ' ';
    background-image: url(${(props) => getS3AssetsUrl(props.bgImg)});
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: 100%;
  }
`;
