import { FC, useState } from 'react';

import { Helmet } from 'react-helmet';

import { ButtonSliders } from 'components/ButtonSliders';
import { preloadImageSources } from 'shared/utils';
import { PanelGroup } from './PanelGroup';
import ResponsiveImg from 'components/ResponsiveImg';
import { TSliderItem } from 'shared/types';

const intelligencePlantStress = 'intelligence-plant-stress.jpg';
const intelligencePestVirus = 'intelligence-pest-virus.jpg';
const intelligenceInsights = 'intelligence-insights.jpg';

const imageSources: string[] = [
  intelligencePlantStress,
  intelligencePestVirus,
  intelligenceInsights,
];

const sliders: TSliderItem[] = [
  {
    button: 'Plant stress',
    description:
      // eslint-disable-next-line sonarjs/no-duplicate-string, max-len
      'By automatically identifying plant stress multiple times a day across your complete canopy you will get a very strong signal for overall plant health and trend. Hot spots and systematic issues will become immediately apparent and allow you to respond quickly.',
  },
  {
    button: 'Pest and virus',
    description:
      'Each leaf is inspected multiple times a day for pest and virus symptoms and detections are reported immediately to minimize crop loss.',
  },
  {
    button: 'Insights & Recommendations',
    description:
      'Actionable insights are derived from thousands of AI generated data points, enabling informed and confident cultivation decisions.',
  },
];

export const NeatleafIntelligence: FC = () => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const imageSrc = imageSources[sliderIndex];

  const preload = preloadImageSources(imageSources);

  return (
    <>
      <Helmet>
        {preload.map((props) => (
          <link key={props.href} {...props} />
        ))}
      </Helmet>
      <PanelGroup
        panels={[
          <ResponsiveImg key="left" filename={imageSrc} />,
          <ButtonSliders
            key="right"
            current={sliderIndex}
            items={sliders}
            title="The Neatleaf Intelligence"
            onChange={setSliderIndex}
          />,
        ]}
      />
    </>
  );
};
