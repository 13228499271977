import { generateResolutionRanges } from './utils';

export const COLORS = {
  black: '#000000',
  black15: '#00000015',
  black50: '#00000050',
  dark1: '#383838',
  dark2: '#282828',
  dark4: '#484848',
  dark6: '#747474',
  dark7: '#808080',
  dark8: '#B4B4B4',
  dark9: '#CFCFCF',
  dark10: '#F8F8F8',
  green1: '#56C254',
  hb1: '#82879A',
  hb2: '#9BA0B6',
  hb3: '#C3C7D9',
  hb4: '#ECEDF1',
  hb5: '#F0F1F4',
  hb6: '#F8F8FC',
  hb7: '#F9F9F9',
  orange1: '#DD382F',
  orange2: '#E86339',
  orange3: '#F48E42',
  orange4: '#EEAB4A',
  white: '#ffffff',
  white1: '#f7f7f7',
  grey1: '#404040',
  grey2: '#585858',
  grey4: '#989898',
  grey5: '#B8B8B8',
  grey6: '#3E4333',
  linearGradientedOrange:
    'linear-gradient(94.46deg, #DD382F 0.05%, #FFB94C 100.05%)',
};

export const SIZES = {
  xxSmall: 4,
  xSmall: 8,
  small: 12,
  medium: 16,
  large: 24,
  xLarge: 32,
  xxLarge: 40,
};

export const SCREEN_SIZES = {
  xxs: 360,
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1400,
};

export const DEVICES = {
  xxs: `(min-width: ${SCREEN_SIZES.xxs}px)`,
  xs: `(min-width: ${SCREEN_SIZES.xs}px)`,
  sm: `(min-width: ${SCREEN_SIZES.sm}px)`,
  md: `(min-width: ${SCREEN_SIZES.md}px)`,
  lg: `(min-width: ${SCREEN_SIZES.lg}px)`,
  xl: `(min-width: ${SCREEN_SIZES.xl}px)`,
};

export const FONTS = {
  h1: { size: 72, style: 'normal', weight: 900, lineHeight: 1.35 },
  h2: { size: 60, style: 'normal', weight: 800, lineHeight: 1.35 },
  h3: { size: 40, style: 'normal', weight: 700, lineHeight: 1.35 },
  h4: { size: 32, style: 'normal', weight: 600, lineHeight: 1.35 },
  h5: { size: 28, style: 'normal', weight: 500, lineHeight: 1.35 },
  h6: { size: 24, style: 'normal', weight: 400, lineHeight: 1.35 },
  body1: { size: 20, style: 'normal', weight: 300, lineHeight: 1.35 },
  body2: { size: 16, style: 'normal', weight: 250, lineHeight: 1.35 },
  body3: { size: 16, style: 'normal', weight: 500, lineHeight: 1.714 },
};

export const FONTS_MOBILE = {
  h1: { size: 60, style: 'normal', weight: 900, lineHeight: 1.35 },
  h2: { size: 40, style: 'normal', weight: 800, lineHeight: 1.35 },
  h3: { size: 32, style: 'normal', weight: 700, lineHeight: 1.35 },
  h4: { size: 28, style: 'normal', weight: 600, lineHeight: 1.35 },
  h5: { size: 24, style: 'normal', weight: 500, lineHeight: 1.35 },
  h6: { size: 20, style: 'normal', weight: 400, lineHeight: 1.35 },
  body1: { size: 16, style: 'normal', weight: 300, lineHeight: 1.35 },
  body2: { size: 12, style: 'normal', weight: 250, lineHeight: 1.35 },
  body3: { size: 14, style: 'normal', weight: 500, lineHeight: 1.5 },
};

export const ROUTES = {
  LANDING: '/',
  ABOUT_US: '/about-us',
  CAREERS: '/careers',
  CONTACT_US: '/contact-us',
  PROMOTION: '/promotion',
  PRIVACY: '/privacy',
  TERMS: '/terms',
  MAINTENANCE: '/maintenance',
  SCHEDULE_DEMO: '/schedule-demo',
  LOGIN: 'https://dashboard.neatleaf.com/',
  BLOG: '/blog',
  NEWSROOM: '/newsroom',
};

export const CULTIVATION_FACILITY: string[] = [
  'Indoor',
  'Greenhouse',
  'Outdoor',
];

export const CULTIVATION_CANOPY: string[] = [
  '<10k',
  '10-50k',
  '50-100k',
  '>100k',
];

// Constants for Image source sets.
export const TYPES = ['webp'];
// Ordering matters
export const RESOLUTIONS = [1440, 1024, 768];
export const EXTENDED_RESOLUTIONS = [2560, ...RESOLUTIONS];
// Ordering matters
export const RESOLUTION_RANGES = generateResolutionRanges(RESOLUTIONS);
export const EXTENDED_RESOLUTIONS_RANGES =
  generateResolutionRanges(EXTENDED_RESOLUTIONS);

export enum FormType {
  CONTACT = 'CONTACT',
  PROMOTION = 'PROMOTION',
  SCHEDULE_DEMO = 'SCHEDULE_DEMO',
}
