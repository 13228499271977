import { FC } from 'react';
import styled from 'styled-components';

import { Hero } from 'components/Landing/Hero';
import { CanopyParallax } from 'components/Landing/CanopyParallax';
import { IndustryValues } from 'components/Landing/IndustryValues';
import { Testimonials } from 'components/Landing/Testimonials';
import { NeatleafSpyder } from 'components/Landing/NeatleafSpyder';
import { NeatleafDashboard } from 'components/Landing/NeatleafDashboard';
import { NeatleafIntelligence } from 'components/Landing/NeatleafIntelligence';
import { Partners } from 'components/Landing/Partners';
import { Footer } from 'components/Footer/Footer';
import { useScrollToTop } from 'hooks';
import { COLORS, DEVICES } from 'shared/constants';

export const Landing: FC = () => {
  useScrollToTop();
  return (
    <Container>
      <Hero />
      <CanopyParallax />
      <IndustryValues />
      <Testimonials />
      <SectionContainer>
        <NeatleafSpyder />
        <NeatleafDashboard />
        <NeatleafIntelligence />
      </SectionContainer>
      <Partners />
      <Footer />
    </Container>
  );
};

const SectionContainer = styled.div`
  > :nth-child(2n) {
    background-color: ${COLORS.white};

    > *:first-child {
      order: initial;

      @media ${DEVICES.sm} {
        order: 1;
      }
    }
  }

  > :nth-child(2n + 1) {
    background-color: ${COLORS.hb7};
  }
`;

const Container = styled.div({
  position: 'relative',
  width: '100%',
});
