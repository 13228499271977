import styled from 'styled-components';
import { Typography } from './Typography';

import { ReactComponent as AlertIcon } from 'assets/svgs/alert-icon.svg';
import { ReactComponent as TickIcon } from 'assets/svgs/tick-icon.svg';
import { ReactNode } from 'react';
import { COLORS } from 'shared/constants';

export enum AlertType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export const AlertMessage = ({
  children,
  type = AlertType.ERROR,
}: {
  children: ReactNode;
  type: AlertType;
}) => {
  let alertColor: keyof typeof COLORS = 'black';
  let Icon = undefined;

  if (type === AlertType.ERROR) {
    alertColor = 'orange1';
    Icon = AlertIcon;
  } else if (type === AlertType.SUCCESS) {
    alertColor = 'green1';
    Icon = TickIcon;
  }

  return (
    <StyledAlertMessage variant="body2" tag="p" color={alertColor}>
      <StyledAlertIcon as={Icon} />
      <span>{children}</span>
    </StyledAlertMessage>
  );
};

const StyledAlertMessage = styled(Typography)`
  display: flex;
  align-items: start;
  gap: 6px;
`;

const StyledAlertIcon = styled.div`
  display: inline-block;
  width: 16px;
  height: 21px; // NOTE: this size is very specific to the overal font sizing, it is to ensure the icon aligns on the center of the first line
  flex-shrink: 0;
`;
