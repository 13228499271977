import { CircularProgress, FormControlLabel } from '@material-ui/core';
import { ChangeEvent, FC, FormEventHandler, useState } from 'react';
import styled from 'styled-components';

import axios from 'axios';
import { Button, Checkbox, TextInput } from 'common';
import { TextAreaInput } from 'common/TextAreaInput';
import config from 'config';
import { COLORS } from 'shared/constants';
import { AlertMessage, AlertType } from 'common/AlertMessage';
import { isEmailValid } from 'shared/utils';
import { GoogleAnalyticsGA4 } from 'shared/analytics/GoogleAnalyticsGA4';
import { EMetricAction, EMetricCategory } from 'shared/analytics/contracts';

const DEFAULT_FORM_STATE = {
  submitting: false,
  success: '',
  error: '',
  validation: {
    name: '',
    email: '',
    message: '',
  },
};
interface IFormProp {
  formType: string;
}
const Form: FC<IFormProp> = ({ formType }: IFormProp) => {
  const [formState, setFormState] = useState(DEFAULT_FORM_STATE);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSigningUpForNewsLetter, setIsSigningUpForNewsLetter] =
    useState(false);

  const handleChangeSigningUpForNewsLetter = (
    _event: ChangeEvent<HTMLInputElement>,
    isChecked: boolean
  ) => {
    setIsSigningUpForNewsLetter(isChecked);
  };

  const handleFormValidation = async () => {
    let validation = { ...DEFAULT_FORM_STATE.validation };

    if (typeof name !== 'string' || !name || name.length <= 3) {
      validation = {
        ...validation,
        name: 'Please enter your name',
      };
    }

    if (typeof email !== 'string' || !email) {
      validation = {
        ...validation,
        email: 'Please enter your email',
      };
    } else if (!isEmailValid(email)) {
      validation = {
        ...validation,
        email: 'Please enter valid email',
      };
    }

    if (typeof message !== 'string' || !message || message.length <= 3) {
      validation = {
        ...validation,
        message: 'Please enter your message',
      };
    }

    const isValid = Object.values(validation).every((field) => !field);

    setFormState({
      ...DEFAULT_FORM_STATE,
      error: !isValid ? 'Please enter valid information' : '',
      validation,
    });

    return isValid;
  };

  const handleSubmit: FormEventHandler = async (event) => {
    event.preventDefault();
    GoogleAnalyticsGA4.sendEvent({
      category: EMetricCategory.CONTACT_US,
      action: EMetricAction.SUBMIT,
      label: email,
    });
    const isValid = await handleFormValidation();

    if (!isValid) {
      return;
    }

    setFormState({
      ...DEFAULT_FORM_STATE,
      submitting: true,
    });

    try {
      await axios.post(
        config.MAIL_SUBMIT_API,
        { name, email, message, isSigningUpForNewsLetter, formType },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
      setFormState({
        ...DEFAULT_FORM_STATE,
        success:
          'Your submission has been received! Thank you for contacting us.',
      });
    } catch (error) {
      setFormState({
        ...DEFAULT_FORM_STATE,
        error:
          'Your submission has not been delivered, please try again later.',
      });
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <TextInput
        label="Full name"
        value={name}
        onChange={setName}
        error={formState.validation.name}
      />
      <TextInput
        type="email"
        label="Email"
        value={email}
        onChange={setEmail}
        error={formState.validation.email}
      />
      <TextAreaInput
        label="Message"
        value={message}
        onChange={setMessage}
        error={formState.validation.message}
      />

      <FormControlLabel
        value={isSigningUpForNewsLetter}
        control={
          <Checkbox
            name="sign-up-for-newsletter"
            checked={isSigningUpForNewsLetter}
            onChange={handleChangeSigningUpForNewsLetter}
          />
        }
        label="Sign me up for the newsletter"
      />

      <StyledFooter>
        <Button
          type="submit"
          color="primary"
          disabled={formState.submitting || !!formState.success}
        >
          Send
        </Button>

        {formState.submitting ? <CircularProgress color="inherit" /> : null}
      </StyledFooter>
      {formState.error && (
        <AlertMessage type={AlertType.ERROR}>{formState.error}</AlertMessage>
      )}
      {formState.success && (
        <AlertMessage type={AlertType.SUCCESS}>
          {formState.success}
        </AlertMessage>
      )}
    </StyledForm>
  );
};

const StyledForm = styled.form`
  display: grid;
  justify-items: start;
  gap: 18px;
`;

const StyledFooter = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 18px;
  min-height: 43px;
  color: ${COLORS.orange3};
`;

export default Form;
