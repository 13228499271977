export type TConfig = {
  MAIL_SUBMIT_API: string;
  S3_ASSEETSS_URL: string;
  google?: {
    prod_ga4_tracking_id: string;
  };
};

const configDev: TConfig = {
  MAIL_SUBMIT_API: 'https://mail.dev.neatleaf.com',
  S3_ASSEETSS_URL:
    'https://neatleaf-dev-landing-page-assets.s3.us-west-2.amazonaws.com',
};

const configProd: TConfig = {
  MAIL_SUBMIT_API: 'https://mail.neatleaf.com',
  S3_ASSEETSS_URL:
    'https://neatleaf-prod-landing-page-assets.s3.us-west-2.amazonaws.com',
  google: {
    prod_ga4_tracking_id: 'G-MSW4N0C2WM',
  },
};

let config: TConfig = configDev;
if (
  process.env.REACT_APP_STAGE === 'prod' ||
  process.env.REACT_APP_STAGE === 'production'
) {
  config = configProd;
}

export default config;
